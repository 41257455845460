import candyOne from "../assets/candyOne.png";
import candyTwo from "../assets/candyTwo.png";
import candyThree from "../assets/candyThree.png";
import candyFour from "../assets/candyFour.png";
import candyFive from "../assets/candyFive.png";
import candySix from "../assets/candySix.png";
import candySeven from "../assets/candySeven.png";

export const candies = [
  candyOne,
  candyTwo,
  candyThree,
  candyFour,
  candyFive,
  candySix,
  candySeven,
];
